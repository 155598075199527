export function intersectionObservers() {
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('opacity-100');
          entry.target.classList.remove('opacity-0');
        }
      });
    },
    {
      rootMargin: '0px',
      threshold: [0, 0.2, 1],
    }
  );

  const tags = document.querySelectorAll('.intersection-reveal');

  tags.forEach((tag) => {
    if (!tag.classList.contains('opacity-0')) {
      tag.classList.add('opacity-0', 'transition-opacity', 'duration-500', 'delay-500');
    }
    observer.observe(tag);
  });
}